<template>
  <div>
    <b-container>
      <b-row>
        <b-col/>
        <b-col cols="auto" style="margin-top: 15%" class="text-center">
          <h1 class="text-white mb-5">
            <b-icon-emoji-neutral font-scale="4" variant="white"/>
          </h1>
          <h5 class="text-white">Die Sitzung ist abgelaufen.</h5>
          <h6 class="text-white">Bitte gehe zur
            <router-link :to="{name: 'Login'}">Anmeldeseite</router-link>
            und melde dich erneut an.
          </h6>
        </b-col>
        <b-col/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SessionExpired",
}
</script>

<style lang="scss" scoped>
</style>
